import Base from "../data/YZB_BASE";
import { cloneDeep, keys } from "lodash";
import { Notify } from "vant";
import { request } from "@/api/service";
const config = {
  coverages: (pageCtx) => [
    {
      fmName: "服务信息",
      fmCode: "coverages",
      show: true,
      formItems: [
        {
          prop: "coverageScope",
          control: "HText",
          show: true,
          config: {
            label: "保障范围",
            placeholder: "",
            disabled: false,
            required: true,
            val: "4",
            text: "发动机",
            rules: [
              {
                required: true,
                message: "保障范围必填",
                trigger: "change",
              },
            ],
          },
        },
        {
          prop: "coverageType",
          control: "HText",
          show: true,
          config: {
            label: "保障类型",
            placeholder: "",
            disabled: false,
            required: true,
            val: "10/20000",
            text: "10月/2万公里",
            rules: [
              {
                required: true,
                message: "保障类型必填",
                trigger: "change",
              },
            ],
          },
        },
      ],
    },
  ],
};
export default {
  scriptConfig: {
    adapterForm: (data) => {
      const resFormData = {};
      const { pageCtx, ctx } = data;
      const cloneform = cloneDeep(pageCtx.form);
      resFormData.vehicle = cloneform.vehicle; //车辆信息
      resFormData.customer = cloneform.customer; //客户信息
      resFormData.saleInfo = cloneform.saleInfo; //销售人员
      resFormData.orderExt = {};
      resFormData.orderExt.transferTime = resFormData.vehicle.transferTime;
      resFormData.orderExt.carAge = resFormData.vehicle.carAge;
      resFormData.orderExt.chineseStandardFlag = "1"; //是否中规车型
      resFormData.orderExt.accidentFlag = "0"; //是否有重大事故
      resFormData.orderExt.fireFlag = "0"; //是否过火车辆
      resFormData.orderExt.floodFlag = "0"; //是否水淹车辆
      // 图片转换
      resFormData.images = [];
      cloneform.images &&
        keys(cloneform.images).map((imageKey) => {
          cloneform.images[imageKey] &&
            cloneform.images[imageKey].length &&
            cloneform.images[imageKey].map((image) => {
              resFormData.images.push(image);
            });
        });
      // 购买服务转换
      if (cloneform.coverages) {
        cloneform.coverages.productCode = "YCKSLA";
        cloneform.coverages.productName = "优质保";
        (cloneform.coverages.coverageScopeName = "发动机"),
          (cloneform.coverages.coverageTypeName = "10月/2万公里"),
          (cloneform.coverages.coveragePeriod = "10");
        resFormData.coverages = [cloneform.coverages];
      }
      //下拉转换
      const { vehicleFormRef, customerFormRef } = pageCtx.$refs;
      //车辆信息
      const { brandCodeRef, useNatureRef, isGasRef } =
        vehicleFormRef && vehicleFormRef[0] && vehicleFormRef[0].$refs;
      resFormData.vehicle.brandName = brandCodeRef && brandCodeRef.currentText;
      resFormData.vehicle.useNatureName =
        useNatureRef && useNatureRef.currentText;
      resFormData.vehicle.isGasName = isGasRef && isGasRef.currentText;
      //客户信息
      const { certificateTypeRef, areaCodesRef } =
        customerFormRef && customerFormRef[0] && customerFormRef[0].$refs;
      resFormData.customer.certificateTypeName =
        certificateTypeRef && certificateTypeRef.currentText;
      resFormData.customer.customerType = "0"; //个人
      const areaCodes = areaCodesRef && areaCodesRef.val;
      if (areaCodes && areaCodes.length > 0) {
        resFormData.customer.provinceCode = areaCodes[0];
        resFormData.customer.cityCode = areaCodes[1];
        resFormData.customer.countyCode = areaCodes[2];
        const currentTexts = areaCodesRef.currentTexts || [];
        resFormData.customer.provinceName = currentTexts[0];
        resFormData.customer.cityName = currentTexts[1];
        resFormData.customer.countyName = currentTexts[2];
      }
      return resFormData;
    },
    submitBefore: async (data) => {
      const { pageCtx, ctx } = data;
      const images = pageCtx.form.images;
      const errorMsg = [];
      if (!(images.idcardFront && images.idcardFront.length > 0)) {
        errorMsg.push("身份证人像面");
      }
      if (!(images.tripImage && images.tripImage.length > 0)) {
        errorMsg.push("车辆仪表盘里程数");
      }
      if (!(images.vehicleLicense && images.vehicleLicense.length > 0)) {
        errorMsg.push("新行驶证正页");
      }
      if (
        !(images.vehicleLicenseBack && images.vehicleLicenseBack.length > 0)
      ) {
        errorMsg.push("新行驶证副页");
      }
      if (!(images.ucSaleContract && images.ucSaleContract.length > 0)) {
        errorMsg.push("二手车交易发票");
      }
      if (errorMsg.length > 0) {
        Notify({
          type: "danger",
          duration: 5000,
          message: `请先上传【${errorMsg}】`,
        });
        return false;
      }
      const saleInfo = pageCtx.form.saleInfo;
      const vehicle = pageCtx.form.vehicle;
      if (!(saleInfo && saleInfo.orgId) && vehicle && vehicle.frameNo) {
        const res = await request({
          url: `/afis-carservice/cfgframeno/config/existFrameNo/${vehicle.frameNo}`,
          method: "get",
        });
        if (!(res && res.orgId)) {
          Notify({
            type: "danger",
            message: "该车辆不符合优质保激活条件，请核实。",
          });
          return false;
        }
        pageCtx.form.saleInfo = res;
      }
      return true;
    },
    submitAfter: (data) => {
      const { pageCtx, ctx, res } = data;
    },
    initAfter: async (data) => {
      const { pageCtx, res } = data;
    },
    initDetailAfter: (data) => {
      const { pageCtx, ctx, res } = data;
    },
  },
  addConfig: (pageCtx) => {
    return [
      ...Base.vehicle.addConfig(pageCtx),
      ...Base.customer.addConfig(pageCtx),
      ...config.coverages(pageCtx),
      ...Base.images.addConfig(pageCtx),
    ];
  },
  editConfig: (pageCtx) => {
    return [
      ...Base.vehicle.editConfig(pageCtx),
      ...Base.customer.editConfig(pageCtx),
      ...config.coverages(pageCtx),
      ...Base.images.editConfig(pageCtx),
    ];
  },
  viewConfig: (pageCtx) => [
    ...Base.vehicle.viewConfig(pageCtx),
    ...Base.customer.viewConfig(pageCtx),
    ...config.coverages(pageCtx),
    ...Base.images.viewConfig(pageCtx),
  ],
};
