<template>
  <!-- <div
    size="mini"
    style="font-size:140%;height:25px;border:none;background:none;margin-top:0px;padding:0;margin:0px;display:inline-block"
  >-->
  <van-uploader :after-read="beforeUpload" style="height: 24px;font-size: 24px;color:#ff8b22">
    <van-loading v-if="loading" type="spinner" />
    <van-icon v-else name="scan" />
  </van-uploader>
  <!-- </div> -->
</template>

<script>
import { Notify } from "vant";
import Common from "@/api/common/index";
import ImagesQuicklyCompress from "images-quickly-compress"; //导出：压缩图片插件

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    side: {
      type: String,
      default:'front'
    },
  },
  data() {
    return {
      imageUrl: "",
      actionUrl: "/afis-engine/api/ocr/recognition",
      loading: false,
      formData: {
        type: "",
        fileUrl: "",
        fileId: "",
      },
    };
  },
  mounted() {},
  methods: {
    beforeUpload(fileData) {
      let file = fileData.file;
      if (file) {
        // debugger;
        // Notify({ type: "danger", message: file.size / 1024 / 1024 });
        // return;

        this.loading = true;
        let ImagesCompress = new ImagesQuicklyCompress({
          mode: "pixel", //根据像素总大小压缩
          num: 1e6, //压缩后图片的总像素都是100万（相当于1000px * 1000px的图片）
          size: "1024kb", //图片大小超过500kb执行压缩
          imageType: file.type, //jpeg压缩效果十分理想
          quality: 1,
          orientation: false,
        });
        ImagesCompress.compressor([file]).then((res) => {
          const files = new window.File(res, file.name, {
            type: file.type,
          });
          const formData = new FormData();
          formData.append("file", files);
          formData.append("type", this.type);
          formData.append("side", this.side);
          formData.append("upload", "true");
          Common.AsyncUpload(this.actionUrl, formData)
            .then((resData) => {
              this.loading = false;
              this.imageUrl = resData.fileUrl;
              // this.formData.type = this.type;
              // this.formData.fileUrl = resData.fileUrl;
              // this.formData.fileId = "";
              this.$emit("imgUpload", {'file':files});
              this.$emit("ocrConvertSuccess", resData);
            })
            .catch((error) => {
              this.loading = false;
              Notify({ type: "danger", message: "识别失败，请重新上传" });
            });
        });
      }
      return false;
    },
  },
};
</script>

<style lang="scss"></style>
