import { request } from "@/api/service";
import { Notify } from "vant";
import areaJson from "@/assets/data/area";
import util from "@/utils/common";
import dayjs from "dayjs";
const getMonthNumber = (date1, date2) => {
  const year1 = new Date(date1).getFullYear();
  const year2 = new Date(date2).getFullYear();
  const month1 = new Date(date1).getMonth() + 1;
  const month2 = new Date(date2).getMonth() + 1;
  let len = (year2 - year1) * 12 + (month2 - month1);
  const day = new Date(date2).getDate() - new Date(date1).getDate();
  if (day > 0) {
    len += 1;
  }
  // else if (day < 0) {
  //   len -= 1
  // }
  return len;
};
const vehicle = {
  async handleFrameNo(pageContext, val) {
    if (!val) {
      return false;
    }
    const res = await request({
      url: `/afis-carservice/cfgframeno/config/existFrameNo/${val}`,
      method: "get",
    });
    if (!(res && res.orgId)) {
      Notify({
        type: "danger",
        message: "该车辆不符合优质保激活条件，请核实。",
      });
      return false;
    }
    if (pageContext.form.saleInfo) {
      Object.assign(pageContext.form.saleInfo, res);
    } else {
      pageContext.form.saleInfo = res;
    }
  },
  handleCarAge(pageContext) {
    const vehicle = pageContext.form && pageContext.form.vehicle;
    if (vehicle.transferTime && vehicle.registerDate) {
      const val = getMonthNumber(vehicle.registerDate, vehicle.transferTime);
      vehicle.carAge = val > 0 ? val : "0";
    } else {
      vehicle.carAge = "0";
    }
  },
  registerVerify: (pageContext, formCode, rule, value, callback) => {
    const vehicle = pageContext.form && pageContext.form.vehicle;
    if (vehicle.transferTime && vehicle.registerDate) {
      const val = getMonthNumber(vehicle.registerDate, vehicle.transferTime);
      return val >= 0;
    } else {
      return true;
    }
  },
};
const customer = {
  handleIdNo: (ctx, formCode) => {
    if (
      ctx.form.customer.certificateType &&
      ctx.form.customer.certificateType === "1" &&
      ctx.form.customer.certificateNo &&
      /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/.test(
        ctx.form.customer.certificateNo
      )
    ) {
      ctx.form.customer.birthday = Date.parse(
        dayjs(util.getBirthDay(ctx.form.customer.certificateNo)).toDate()
      );
      ctx.form.customer.gender = util.getGender(
        ctx.form.customer.certificateNo
      );
    }
  },
  certificateNo: (ctx, formCode, rule, value, callback) => {
    if (
      ctx.form[formCode].certificateType === "1" &&
      !/(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/.test(
        value
      )
    ) {
      return false;
    }
    return true;
  },
};
const config = {
  vehicle: (pageCtx) => [
    {
      fmName: "车辆信息",
      fmCode: "vehicle",
      show: true,
      labelWidth: "7em",
      formItems: [
        {
          prop: "brandCode",
          control: "HTextSelect",
          show: true,
          config: {
            label: "品牌",
            placeholder: "请选择品牌",
            disabled: false,
            required: true,
            val: null,
            apiMethod: "get",
            apiCode: "/afis-product/cmc/carBrand/dictCode/listAll",
            rules: [
              {
                required: true,
                message: "品牌必选",
                trigger: "onBlur",
              },
            ],
            clickEvent: (ctx) => {
              pageCtx.tempData();
              const productCode = pageCtx.productCode;
              ctx.$router.push({
                path: `/product/order/brandCodeSelect?productCode=${productCode}`,
              });
            },
          },
        },
        {
          prop: "brandAndModel",
          control: "HInput",
          show: true,
          config: {
            label: "品牌型号",
            placeholder: "请输入品牌型号",
            disabled: false,
            required: true,
            maxlength: 50,
            val: null,
            rules: [
              {
                required: true,
                message: "品牌型号必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "frameNo",
          control: "HInput",
          show: true,
          config: {
            label: "车架号",
            placeholder: "请输入车架号",
            disabled: false,
            required: true,
            maxlength: 17,
            toUpperCase: true,
            val: null,
            rules: [
              {
                required: true,
                message: "车架号必填",
                trigger: "onBlur",
              },
              {
                pattern: /^[a-zA-Z0-9]{17}$/,
                message: "车架号格式有误",
                trigger: "onBlur",
              },
            ],
            changeEvent: (ctx) => {
              const { pageContext, val } = ctx;
              vehicle.handleFrameNo(pageContext, val);
            },
          },
        },
        {
          prop: "engineNo",
          control: "HInput",
          show: true,
          config: {
            label: "发动机号",
            placeholder: "请输入发动机号",
            disabled: false,
            required: true,
            toUpperCase: true,
            maxlength: 20,
            val: null,
            rules: [
              {
                required: true,
                message: "发动机号必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "plateNo",
          control: "HInput",
          show: true,
          config: {
            label: "车牌号",
            placeholder: "请输入车牌号",
            disabled: false,
            required: true,
            toUpperCase: true,
            val: null,
            rules: [
              {
                required: true,
                message: "车牌号必填",
                trigger: "onBlur",
              },
              {
                pattern: /^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[DF])|([DF]([A-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-Z0-9]{4}[A-Z0-9挂学警港澳使领])|(临[A-Z0-9]{6}))$/,
                message: "车牌号格式有误",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "registerDate",
          control: "HDatePicker",
          show: true,
          config: {
            label: "车辆注册日期",
            placeholder: "请选择车辆注册日期",
            disabled: false,
            required: true,
            val: null,
            maxDate: new Date(),
            rules: [
              {
                required: true,
                message: "车辆注册日期必填",
                trigger: "onChange",
              },
              {
                validator: (value, rule) => {
                  return vehicle.registerVerify(
                    pageCtx,
                    "vehicle",
                    rule,
                    value
                  );
                },
                message: "应该小于过户日期",
                trigger: "onChange",
              },
            ],
            confirmEvent: (ctx) => {
              const { pageContext, val } = ctx;
              vehicle.handleCarAge(pageContext, val);
            },
          },
        },
        {
          prop: "transferTime",
          control: "HDatePicker",
          show: true,
          config: {
            label: "过户日期",
            placeholder: "请选择过户日期",
            disabled: false,
            required: true,
            maxDate: new Date(),
            val: null,
            rules: [
              {
                required: true,
                message: "过户日期必填",
                trigger: "onChange",
              },
              {
                validator: (value, rule) => {
                  return vehicle.registerVerify(
                    pageCtx,
                    "vehicle",
                    rule,
                    value
                  );
                },
                message: "应该大于车辆注册日期",
                trigger: "onChange",
              },
            ],
            confirmEvent: (ctx) => {
              const { pageContext, val } = ctx;
              vehicle.handleCarAge(pageContext, val);
            },
          },
        },
        {
          prop: "carAge",
          control: "HText",
          show: true,
          config: {
            label: "二手车车龄",
            placeholder: "",
            disabled: false,
            required: true,
            val: "0",
            append: "月",
            rules: [
              {
                required: true,
                message: "二手车车龄必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "currMileage",
          control: "HInput",
          show: true,
          config: {
            label: "当前行驶里程",
            placeholder: "请输入当前行驶里程",
            disabled: false,
            required: true,
            maxlength: 8,
            type: "number",
            append: "公里",
            val: null,
            rules: [
              {
                required: true,
                message: "当前行驶里程必填",
                trigger: "onBlur",
              },
              {
                pattern: /^[0-9]\d*$/,
                message: "当前行驶里程格式有误",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "useNature",
          control: "HSelect",
          show: true,
          config: {
            label: "车辆使用性质",
            placeholder: "请选择车辆使用性质",
            disabled: false,
            required: true,
            val: "2",
            dictCode: "CS_USE_NATURE",
            rules: [
              {
                required: true,
                message: "车辆使用性质必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "isGas",
          control: "HSelect",
          show: true,
          config: {
            label: "燃料类型",
            placeholder: "请选择燃料类型",
            disabled: false,
            required: true,
            val: "1",
            dictCode: "CS_GAS",
            rules: [
              {
                required: true,
                message: "燃料类型必填",
                trigger: "onBlur",
              },
            ],
          },
        },
      ],
    },
  ],
  customer: (pageCtx) => [
    {
      fmName: "客户信息",
      fmCode: "customer",
      show: true,
      formChange: (ctx) => {},
      formItems: [
        {
          prop: "certificateType",
          control: "HSelect",
          show: true,
          config: {
            label: "证件类型",
            placeholder: "请选择证件类型",
            disabled: false,
            required: true,
            val: "1",
            dictCode: "CERTIFICATE_TYPE_PER",
            rules: [
              {
                required: true,
                message: "证件类型必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "certificateNo",
          control: "HInput",
          show: true,
          config: {
            label: "证件号码",
            placeholder: "请输入证件号码",
            disabled: false,
            required: true,
            val: null,
            rules: [
              {
                required: true,
                message: "证件号码必填",
                trigger: "onBlur",
              },
              {
                validator: (value, rule) => {
                  return customer.certificateNo(
                    pageCtx,
                    "customer",
                    rule,
                    value
                  );
                },
                message: "身份证号格式有误",
                trigger: "onBlur",
              },
            ],
            changeEvent: (ctx) => {
              const { pageContext, val } = ctx;
              customer.handleIdNo(pageContext, val);
            },
          },
        },
        {
          prop: "customerName",
          control: "HInput",
          show: true,
          config: {
            label: "客户姓名",
            placeholder: "请输入客户姓名",
            disabled: false,
            required: true,
            val: null,
            rules: [
              {
                required: true,
                message: "客户姓名必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "phone",
          control: "HInput",
          show: true,
          config: {
            label: "手机号码",
            placeholder: "请输入手机号码",
            disabled: false,
            required: true,
            type: "number",
            maxlength: 11,
            val: null,
            rules: [
              {
                required: true,
                message: "手机号码必填",
                trigger: "onBlur",
              },
              {
                pattern: /^((0\d{2,3}\d{7,8})|(1[3456789]\d{9}))$/,
                message: "手机号格式有误",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "areaCodes",
          control: "HCascader",
          show: true,
          config: {
            label: "所在地区",
            placeholder: "请选择所在地区",
            disabled: false,
            required: true,
            val: null,
            options: areaJson,
            rules: [
              {
                type: "array",
                required: true,
                message: "所在地区必填",
                trigger: "onChange",
              },
            ],
          },
        },
        {
          prop: "address",
          control: "HInput",
          show: true,
          config: {
            label: "详细地址",
            placeholder: "请输入详细地址",
            disabled: false,
            required: true,
            val: null,
            rules: [
              {
                required: true,
                message: "详细地址必填",
                trigger: "onBlur",
              },
            ],
          },
        },
      ],
    },
  ],
  images: (pageCtx) => [
    {
      fmName: "影像信息",
      collapseName: "images",
      fmCode: "images",
      show: true,
      formItems: [
        {
          prop: "idcardFront",
          control: "HUpload",
          show: true,
          config: {
            label: "身份证人像面",
            required: true,
            busiType: "contractAttachment",
            previewSize: 100,
            iconImgUrl: require("@/assets/images/upload/idCard.png"),
            extraParam: {
              objCode: "customer",
              fieldCode: "idcardFront",
              fieldName: "身份证人像面",
            },
            val: [],
            rules: [
              {
                required: true,
                message: "身份证人像面必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "tripImage",
          control: "HUpload",
          show: true,
          config: {
            label: "车辆仪表盘里程数",
            required: true,
            busiType: "contractAttachment",
            previewSize: 100,
            iconImgUrl: require("@/assets/images/upload/odometer.png"),
            extraParam: {
              objCode: "vehicle",
              fieldCode: "tripImage",
              fieldName: "车辆仪表盘里程数",
            },
            rules: [
              {
                required: true,
                message: "车辆仪表盘里程数必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "vehicleLicense",
          control: "HUpload",
          show: true,
          config: {
            label: "新行驶证正页",
            required: true,
            busiType: "contractAttachment",
            previewSize: 100,
            iconImgUrl: require("@/assets/images/upload/drivinglicense_front.png"),
            extraParam: {
              objCode: "vehicle",
              fieldCode: "vehicleLicense",
              fieldName: "新行驶证正页",
            },
            val: [],
            rules: [
              {
                required: true,
                message: "新行驶证正页必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "vehicleLicenseBack",
          control: "HUpload",
          show: true,
          config: {
            label: "新行驶证副页",
            required: true,
            busiType: "contractAttachment",
            previewSize: 100,
            iconImgUrl: require("@/assets/images/upload/drivinglicense_behind.png"),
            extraParam: {
              objCode: "vehicle",
              fieldCode: "vehicleLicenseBack",
              fieldName: "新行驶证副页",
            },
            val: [],
            rules: [
              {
                required: true,
                message: "新行驶证副页必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "ucSaleContract",
          control: "HUpload",
          show: true,
          config: {
            label: "二手车交易发票",
            required: true,
            busiType: "contractAttachment",
            previewSize: 100,
            iconImgUrl: require("@/assets/images/upload/ucSaleContract.png"),
            extraParam: {
              objCode: "vehicle",
              fieldCode: "ucSaleContract",
              fieldName: "二手车交易发票",
            },
            rules: [
              {
                required: true,
                message: "二手车交易发票必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "testReport",
          control: "HUpload",
          show: true,
          config: {
            label: "认证检测报告",
            busiType: "contractAttachment",
            previewSize: 100,
            iconImgUrl: require("@/assets/images/upload/testReport.png"),
            extraParam: {
              objCode: "vehicle",
              fieldCode: "testReport",
              fieldName: "认证检测报告",
            },
          },
        },
      ],
    },
  ],
};
export default {
  vehicle: {
    addConfig: (pageCtx) => config.vehicle(pageCtx),
    editConfig: (pageCtx) => config.vehicle(pageCtx),
    viewConfig: (pageCtx) => config.vehicle(pageCtx),
  },
  customer: {
    addConfig: (pageCtx) => config.customer(pageCtx),
    editConfig: (pageCtx) => config.customer(pageCtx),
    viewConfig: (pageCtx) => config.customer(pageCtx),
  },
  images: {
    addConfig: (pageCtx) => config.images(pageCtx),
    editConfig: (pageCtx) => config.images(pageCtx),
    viewConfig: (pageCtx) => config.images(pageCtx),
  },
};
