<template>
  <div class="container">
    <div v-if="config.formMap.addForm">
      <div
        v-for="(formItem, index) in config.formMap.addForm"
        :key="index"
        class="form shadow shadow-lg shadow-warp"
      >
        <div class="form-title">
          <span>{{ formItem.fmName }}</span>
        </div>
        <div class="form-content">
          <HYForm
            :ref="formItem.fmCode + 'FormRef'"
            :config="formItem"
            :form="form[formItem.fmCode]"
          >
          </HYForm>
        </div>
      </div>
      <div style="padding:10px 8px">
        <van-checkbox
          v-model="pageData.isAgreement"
          checked-color="#57ccc2"
          shape="square"
          icon-size="16px"
        ></van-checkbox>
        <span style="font-size:12px;padding-left:6px"
          >本人已阅读并理解<b style="color:#57ccc2">《优质保服务条款 》</b
          >内容，特别是对于其中责任免除条款、注意事项条款已充分注意和理解其含义和法律后果，同意并接受包括免责情形在内的所有服务内容。</span
        >
      </div>
      <div>
        <van-button
          block
          type="primary"
          :loading="pageData.submitLoading"
          @click="submit"
          >激活提交</van-button
        >
      </div>
    </div>
    <van-dialog
      v-model="errorShow"
      :showConfirmButton="false"
      :showCancelButton="false"
      show-cancel-button
    >
      <p
        style="text-align: right;
        font-size: 20px;
        padding-right: 15px;"
      >
        <van-icon name="cross" color="#666666" @click="errorShow = false" />
      </p>
      <p style="text-align: center;">
        <van-image :src="require('@/assets/images/fail.png')" />
      </p>
      <p style="text-align: center; font-weight: bold;">激活失败</p>
      <p class="fail-reason">{{ ruleMsgs }}</p>
    </van-dialog>
  </div>
</template>
<script>
import HYForm from "@/components/Form.vue";
import layout from "@/views/product/layout/index";
import { mapState } from "vuex";
import { request } from "@/api/service";
import Common from "@/api/common/index";
import ocrConvert from "../components/ocrConvert";
import { Notify } from "vant";
export default {
  name: "ProductIndex",
  components: { HYForm },
  provide() {
    return {
      pageContext: this.pageContext,
    };
  },
  data() {
    return {
      pageData: {
        submitLoading: false,
        isAgreement: true,
      },
      pageContext: this,
      productCode: null,
      formMode: "add",
      form: {},
      config: {
        scriptConfig: null,
        formMap: {
          addForm: null,
          editForm: null,
          viewForm: null,
        },
      },
      errorShow: false,
      ruleMsgs: "",
    };
  },
  computed: {
    ...mapState("dict", ["dictMap"]),
  },
  async created() {
    document.title = "优质保";
    await this.initData();
    const layoutData = this.initLayout();
    if (layoutData === null) {
      Notify({ type: "danger", message: "该产品未配置，请联系管理人员" });
      return;
    }
  },
  mounted() {},
  methods: {
    async initData() {
      // 字典加载
      await this.$store.dispatch("dict/loadDict");
    },
    initDetail(productCode) {
      const getForm =
        this.$store.getters.productForm &&
        this.$store.getters.productForm[productCode];
      if (!getForm) {
        return false;
      }
      this.form.vehicle = getForm.vehicle || {};
      this.form.customer = getForm.customer || {};
      this.form.saleInfo = getForm.saleInfo || {};
      //图片信息
      const images = getForm.images;
      if (images && images.length) {
        const imageObj = {};
        images.map((image) => {
          image.url = image.filePath;
          image.name = image.fileName;
          image.isImage = true;
          if (imageObj[image.fieldCode]) {
            imageObj[image.fieldCode].push(image);
          } else {
            imageObj[image.fieldCode] = [image];
          }
        });
        this.form.images = imageObj;
      }
    },
    initLayout() {
      const { productCode } = this.$route.query;
      this.productCode = productCode;
      const layoutData = layout(productCode);
      if (layoutData == null) {
        return null;
      }
      this.config.scriptConfig = layoutData.scriptConfig;
      const layoutAddData = layoutData.addConfig(this);
      layoutAddData &&
        layoutAddData.forEach((formItem) => {
          this.$set(this.form, formItem.fmCode, formItem.form || {});
          const formItems = formItem.formItems;
          formItems &&
            formItems.forEach((item) => {
              this.$set(
                this.form[formItem.fmCode],
                item.prop,
                item.config && item.config.val
              );
            });
        });
      this.config.formMap.addForm = layoutAddData;
      this.initDetail(productCode);
      //品牌特殊处理
      const { brandCode } = this.$route.query;
      if (brandCode && this.form.vehicle) {
        this.form.vehicle.brandCode = brandCode;
      }
      return layoutData;
    },
    adapterForm(form, ctx) {
      if (this.config.scriptConfig.adapterForm) {
        return this.config.scriptConfig.adapterForm({
          pageCtx: this,
          ctx: ctx,
        });
      }
      return form;
    },
    async handleSubmitBefore(ctx) {
      if (this.config.scriptConfig.submitBefore) {
        return await this.config.scriptConfig.submitBefore({
          pageCtx: this,
          ctx: ctx,
        });
      }
      return true;
    },
    handleSubmitAfter(ctx, res) {
      this.config.scriptConfig.submitAfter &&
        this.config.scriptConfig.submitAfter({
          pageCtx: this,
          ctx: ctx,
          res: res,
        });
    },
    handleAgreement() {
      this.tempData();
      const { productCode } = this.$route.query;
      this.$router.push({
        path: `/agreement/serviceTerm?productCode=${productCode}`,
      });
    },
    tempData() {
      const { productCode } = this.$route.query;
      //转换提交数据
      const submitForm = this.adapterForm(this.form);
      this.$store.dispatch("product/setForm", {
        productCode: productCode,
        form: submitForm,
      }); //保存缓存
      return submitForm;
    },
    async submit() {
      //表单数据
      // this.form.vehicle=this.$refs.vehicleFormRef[0].form //车辆信息
      // this.form.customer=this.$refs.customerFormRef[0].form //客户信息
      // this.form.coverages=this.$refs.coveragesFormRef[0].form //服务信息
      // this.form.images=this.$refs.imagesFormRef[0].form //服务信息

      let flag = true;
      for (const item in this.$refs) {
        if (item.indexOf("FormRef") === -1) {
          continue;
        }
        const formRef = this.$refs[item][0];
        const validate = await formRef.validateForm();
        if (!validate) {
          flag = false;
        }
      }
      if (!flag) {
        Notify({ type: "danger", message: "请完善录入信息" });
        return false;
      }
      if (!(await this.handleSubmitBefore())) {
        return false;
      }
      const { productCode } = this.$route.query;
      //转换提交数据
      const submitForm = this.adapterForm(this.form);
      this.$store.dispatch("product/setForm", {
        productCode: productCode,
        form: submitForm,
      }); //保存缓存
      if (!this.pageData.isAgreement) {
        Notify({ type: "warning", message: "请先阅读并同意协议信息" });
        return false;
      }
      //调用服务验证
      this.pageData.submitLoading = true;
      try {
        const res = await request({
          url: "/afis-carservice/contract/order/web/verification",
          method: "post",
          data: submitForm,
        });
        this.pageData.submitLoading = false;
        if (res.code === 40010) {
          //核保不通过
          this.ruleMsgs =
            res.data && res.data.ruleMsgs && res.data.ruleMsgs.join(";");
          this.errorShow = true;
          return false;
        }
        this.handleSubmitAfter();
        this.$router.push({ path: `成功页面` });
      } catch (e) {
        this.pageData.submitLoading = false;
      }
    },
    getMonthNumber(date1, date2) {
      const year1 = new Date(date1).getFullYear();
      const year2 = new Date(date2).getFullYear();
      const month1 = new Date(date1).getMonth() + 1;
      const month2 = new Date(date2).getMonth() + 1;
      let len = (year2 - year1) * 12 + (month2 - month1);
      const day = new Date(date2).getDate() - new Date(date1).getDate();
      if (day > 0) {
        len += 1;
      }
      // else if (day < 0) {
      //   len -= 1
      // }
      return len;
    },
    carConvertSuccess(data) {
      const vehicle = this.form.vehicle;
      vehicle.plateNo = data.plateNo;
      vehicle.frameNo = data.frameNo;
      vehicle.engineNo = data.engineNo;
      vehicle.brandAndModel = data.brandModel;
      vehicle.registerDate =
        data.registDate && new Date(data.registDate).getTime();
      vehicle.transferTime =
        data.issueDate && new Date(data.issueDate).getTime();
      vehicle.useNature = data.useProperty === "营运" ? "1" : "2";
      //月处理
      if (vehicle.transferTime && vehicle.registerDate) {
        const val = this.getMonthNumber(
          vehicle.registerDate,
          vehicle.transferTime
        );
        vehicle.carAge = val > 0 ? val : "0";
      } else {
        vehicle.carAge = "0";
      }
    },
    carConvertImgUpload({ file }) {
      this.handleOcrFile(
        file,
        {
          objCode: "vehicle",
          fieldCode: "vehicleLicense",
          fieldName: "新行驶证正页",
        },
        (resData) => {
          this.form.images.vehicleLicense = [resData];
        }
      );
    },
    carBackConvertSuccess(data) {
      const vehicle = this.form.vehicle;
      vehicle.isGas = data.fuelType === "汽油" ? "1" : "0";
    },
    carBackConvertImgUpload({ file }) {
      this.handleOcrFile(
        file,
        {
          objCode: "vehicle",
          fieldCode: "vehicleLicenseBack",
          fieldName: "新行驶证副页",
        },
        (resData) => {
          this.form.images.vehicleLicenseBack = [resData];
        }
      );
    },
    ownerConvertSuccess(data) {
      const customer = this.form.customer;
      customer.customerName = data.name ? data.name : data.companyName;
      customer.certificateNo = data.idNumber
        ? data.idNumber
        : data.socialCreditCode;
      customer.gender = data.sex === "男" ? "1" : "2";
      customer.birthday = data.birthDate && Date.parse(data.birthDate);
    },
    ownerConvertImgUpload({ file }) {
      this.handleOcrFile(
        file,
        {
          objCode: "customer",
          fieldCode: "idcardFront",
          fieldName: "身份证人像面",
        },
        (resData) => {
          this.form.images.idcardFront = [resData];
        }
      );
    },
    async handleOcrFile(file, extraParam, success) {
      const isLt5M = file.size / 1024 / 1024 < 10;
      if (!isLt5M) {
        //this.$message.error('请上传10M以下的文件')
        return false;
      }
      // 通过 FormData 对象上传文件
      const formData = new FormData();
      formData.append("file", file);
      formData.append("busiType", "productImage");
      formData.append("category", "public");
      // 发起请求
      const res = await Common.AsyncUpload(
        "/afis-engine/dfs/storage/upload",
        formData
      );
      const fileObj = {
        fileId: res.fileId,
        filePath: res.fileUrl,
        fileName: file.name,
        fileType: file.type,
        fileSize: file.size,
        name: file.name,
        url: res.fileUrl,
        isImage: true,
      };
      const resObj = Object.assign(fileObj, extraParam);
      success(resObj);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
::v-deep .van-checkbox {
  display: inline-block;
}
.form {
  border-radius: $border-radius-lg;
  overflow: hidden;
  margin-bottom: 10px;
  background-color: $white;
  position: relative;
  box-shadow: 0 0 10upx rgba(0, 0, 0, 0.1);
  margin: 10px 5px;
  .form-title {
    color: $text-color;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold-lg;
    line-height: $line-height-lg;
    padding: $padding-xs;
    span {
      border-left: 5px solid $primary-color;
      padding: 2px 8px;
    }
  }
}
.fail-reason {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-family: 微软雅黑;
  color: rgb(102, 102, 102);
  line-height: 30px;
  padding: 0px 20px;
  word-wrap: break-word;
  padding-bottom: 20px;
}
</style>
